import React from 'react'
import Button from '../components/shortcodes/Button'
import { PageTemplate } from '../templates/page'
import { destroy } from 'cart-localstorage'
import GetQueryParameter from '../scripts/get-query-parameter'

const pageProps = {
  pageIdentifier: "thanks",
  pageClass: "hide-cart",
  showCta: false,
}

const ThanksPage = ({location}) => {
  // Prevent Gatsby from prerendering this component
  if (typeof window == 'undefined') {
    return false
  }
  
  let orderId = GetQueryParameter('order_id', location.href)

  // It's an order Thank You page
  if ( orderId ) {
    // Delete products from cart
    destroy()

    return (
      <PageTemplate 
        title="Order Received!"
        subtitle={"Your Order reference is <strong class='like-button'>" + orderId + "</strong>"}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass + ' has-text-centered'}
        showCta={pageProps.showCta}
      >
        <Button url="/" text="Go to Shop" type="is-link" />
      </PageTemplate>
    )
  }
  // It's an enquiry Thank You page
  else {
    return (
      <PageTemplate 
        title="Thank You!"
        subtitle="We received your enquiry and will get back to you as soon as possible — usually within 24h."
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCta={pageProps.showCta}
      />
    )
  }
}

export default ThanksPage